import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';

import {
    Layout,
    Container,
    GridContainer,
    NavigationContainer,
    IntroductionIconContainer,
    TimerTopBar,
    TimerContainer,
    ArrowButtonWrapper,
    ButtonsWrapper,
} from './QuestionnaireController.style';
import { setQuestionnaireReadyForRendering } from '../questionnaire-renderer';

import ShadowButton from 'components/shared/ShadowButton/ShadowButton';
import QuestionnaireGrid from '../QuestionnaireGrid/QuestionnaireGrid';
import ModuleIntroductionView from 'components/shared/ModuleIntroductionView/ModuleIntroductionView';
import MentalTestIntroduction from 'components/MentalTestIntroduction/MentalTestIntroduction';
import DataCollectionIntro from '../../DataCollectionIntro/DataCollectionIntro';

import CustomLayout from 'components/shared/CustomLayout/CustomLayout';
import TimerAlertModal from 'components/shared/TimerAlertModal/TimerAlertModal';
import { buildForm } from '../questionnaire-renderer';

import { ReactComponent as ReportIcon } from 'assets/icons/report.svg';
import SessionAnalytics from 'services/SessionAnalytics';
import CulturePreferencesIntro from 'components/CultureFit/CulturePreferencesIntro/CulturePreferencesIntro';

import Timer from 'react-compound-timer';
import withMediaQuery from '../../../hoc/MediaQuery/withMediaQuery';
import { isMobileInLandscapeMode } from '../../../utils/landscapeDetector';
import { ChevronDownIcon, ChevronUpIcon } from 'evergreen-ui';

const defaultTimerFormat = (value) =>
    `${value < 10 ? `0${value}:` : `${value}:`}`;

const QuestionnaireController = (props) => {
    const {
        module,
        modules,
        language,
        OnResetRecordingChunk,
        onRecordUserMedia,
        onStopRecordingUserMedia,
        onQuestionnaireComplete,
        isQuestionnaireOnlyScan,
        t,
    } = props;

    const isWorkPreferencesQuestionnaire = module.id.includes('culture');

    const questionnaire = module.questionnaire[language];

    const formattedQuestionnaire =
        setQuestionnaireReadyForRendering(questionnaire);

    const QuestionnaireHasTwoPage = formattedQuestionnaire.pages.some(
        (step) => step.grid.length === 2,
    );

    if (QuestionnaireHasTwoPage && isWorkPreferencesQuestionnaire) {
        const combinedQuestions = formattedQuestionnaire.pages.flatMap((page) =>
            page.grid.flatMap((gridItem) =>
                gridItem.questions.map((question) => ({
                    ...question,
                    statement: gridItem.statement,
                })),
            ),
        );

        const combinedGrid = [
            {
                id: 'combined-grid',
                type: 'statement/multiple-choice-grid',
                statement: 'All work preferences questions combined',
                questions: combinedQuestions,
            },
        ];

        const singlePageQuestionnaire = {
            pages: [
                {
                    grid: combinedGrid,
                    id: 1,
                },
            ],
        };
        formattedQuestionnaire.pages = singlePageQuestionnaire.pages;
    }

    const initialPages = formattedQuestionnaire.pages.map((step) => {
        return {
            ...step,
            answers: {},
            completed: false,
        };
    });
    const orderByPageId = formattedQuestionnaire.pages.map((step) => step.id);
    const moduleTimer = module.timer ? module.timer : null;

    const showTimer = !!moduleTimer;
    const initialShowTimerTime = !!moduleTimer;

    const [timerBeeping, setTimerBeeping] = useState(false);
    const [showEndTimerModal, setShowEndTimerModal] = useState(null);
    const [showTimerTime, setShowTimerTime] = useState(initialShowTimerTime);
    const [introductionAccepted, setIntroductionAccepted] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1);
    const [slideFadeInAndOut, setSlideFadeInAndOut] = useState('entering');
    const [transitionDirection, setTransitionDirection] = useState('forward');
    const [blinkAnimation, setBlinkAnimation] = useState(false);
    const [pages, setPages] = useState(initialPages);
    const [currentPage, setCurrentPage] = useState(initialPages[0]);
    const touchStartRef = useRef(0);
    const touchEndRef = useRef(0);
    const workPreferencesQuestionsLength =
        formattedQuestionnaire?.pages[0]?.grid[0]?.questions?.length;
    const [questionnaireGridForm, setQuestionnaireGridForm] = useState(
        buildForm(currentPage),
    );
    const [formData, setFormData] = useState({
        id: questionnaire.id,
        questions: [],
        answers: [],
    });
    const formDataRef = useRef(formData);
    formDataRef.current = formData;

    const updateFormQuestions = (answers) => {
        const newAnswers = [];
        const form = buildForm(currentPage);
        if (Array.isArray(answers)) {
            Object.keys(form).forEach((questionKey) => {
                const key = questionKey.toString();
                const answer = answers.find((a) => a.id === key);
                newAnswers.push({
                    id: key,
                    value: answer && answer.value ? answer.value : null,
                });
            });
        } else {
            Object.keys(form).forEach((questionKey) => {
                const key = questionKey.toString();
                newAnswers.push({
                    id: key,
                    value: answers[key] ? answers[key] : null,
                });
            });
        }

        const oldAnswers = formData.questions.filter(
            (item) => !newAnswers.find((answer) => answer.id === item.id),
        );

        return [...oldAnswers, ...newAnswers];
    };

    const onPageUpdated = (form, isValid) => {
        const updatedAnswers = updateFormQuestions(form);
        setPages(
            pages.map((page) => {
                if (page.id === currentPage.id) {
                    return { ...page, completed: isValid, answers: form };
                } else return page;
            }),
        );
        setCurrentPage({ ...currentPage, completed: isValid });
        setFormData({
            ...formData,
            questions: updatedAnswers,
        });
    };

    const submitQuestionnaireAndStopVideo = () => {
        if (moduleTimer) {
            SessionAnalytics.onTimerModuleEnd();
        }
        setIntroductionAccepted(false);
        onQuestionnaireComplete(formDataRef.current, formData.id);
    };

    const acceptIntroduction = () => {
        if (moduleTimer) {
            SessionAnalytics.onTimerStart(module.id);
        }
        setIntroductionAccepted(true);
    };

    const isFirstPage = () => {
        return orderByPageId.indexOf(currentPage.id) === 0;
    };

    const isLastPage = () => {
        const lastStep = pages[pages.length - 1];
        return currentPage.id === lastStep.id;
    };

    const navigationContainerLayout = () => {
        if (moduleTimer && moduleTimer.perPage) {
            return 'one-row';
        } else {
            return isFirstPage() ? 'one-row' : 'two-row';
        }
    };

    const shouldNextButtonBeDisabled = () => {
        if (showTimer) {
            return false;
        } else {
            return !currentPage.completed;
        }
    };

    const goToPreviousQuestion = () => {
        if (
            currentQuestionIndex > 1 &&
            currentQuestionIndex <= workPreferencesQuestionsLength
        ) {
            setTransitionDirection('backward');
            setSlideFadeInAndOut('exiting');
            setTimeout(() => {
                setCurrentQuestionIndex(currentQuestionIndex - 1);
                setSlideFadeInAndOut('entering');
            }, 500);
        }
    };
    const goToNextQuestion = () => {
        if (
            currentQuestionIndex > 0 &&
            currentQuestionIndex < workPreferencesQuestionsLength
        ) {
            setTransitionDirection('forward');
            setSlideFadeInAndOut('exiting');
            setTimeout(() => {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
                setSlideFadeInAndOut('entering');
            }, 500);
        }
    };

    const isCurrentQuestionAnswered = () => {
        return formData.questions.some(
            (question) =>
                question.id === `culturefit-question-${currentQuestionIndex}` &&
                !!question.value,
        );
    };

    const navigateBackward = () => {
        const lastPageId =
            orderByPageId[orderByPageId.indexOf(currentPage.id) - 1];
        const lastPage = pages.find((step) => step.id === lastPageId);

        if (lastPage) {
            setCurrentPage(lastPage);
            window.scrollTo(0, 0);
        }
    };

    const navigateForward = () => {
        const nextPageId =
            orderByPageId[orderByPageId.indexOf(currentPage.id) + 1];
        const nextPage = pages.find((step) => step.id === nextPageId);
        if (
            (currentPage.completed === true ||
                (moduleTimer &&
                    moduleTimer.time &&
                    moduleTimer.perPage === true)) &&
            nextPage
        ) {
            setCurrentPage(nextPage);
            window.scrollTo(0, 0);
        }
        if (moduleTimer && moduleTimer.time && moduleTimer.perPage === true) {
            if (nextPage) {
                SessionAnalytics.onTimerPageFinished();
            }
            resetTimer();
        }
    };

    const showBackwardButton = () => {
        if (moduleTimer && moduleTimer.perPage) {
            return false;
        }
        return !isFirstPage();
    };

    const onModalTimerEnd = () => {
        setShowEndTimerModal(false);
        if (module.id === 'mental-test') {
            onRecordUserMedia(`mental-test-${currentPage.id + 1}`);
        }
        navigateForward();
    };

    const onTimerEnd = () => {
        setTimerBeeping(false);
        if (!isLastPage()) {
            onStopRecordingUserMedia();
            setShowEndTimerModal(true);
        } else {
            submitQuestionnaireAndStopVideo();
        }
    };

    const resetTimer = () => {
        setShowTimerTime(false);
        setTimerBeeping(false);
    };

    useEffect(() => {
        if (
            isWorkPreferencesQuestionnaire &&
            !isMobileInLandscapeMode() &&
            window.innerHeight > 500
        ) {
            const handleKeyDown = (event) => {
                if (event.key === 'ArrowUp' && currentQuestionIndex !== 1) {
                    goToPreviousQuestion();
                } else if (
                    event.key === 'ArrowDown' &&
                    currentQuestionIndex !== workPreferencesQuestionsLength &&
                    isCurrentQuestionAnswered()
                ) {
                    goToNextQuestion();
                }
            };

            const handleWheel = (event) => {
                if (event.deltaY < 0 && currentQuestionIndex !== 1) {
                    goToPreviousQuestion();
                } else if (
                    event.deltaY > 0 &&
                    currentQuestionIndex !== workPreferencesQuestionsLength &&
                    isCurrentQuestionAnswered()
                ) {
                    goToNextQuestion();
                }
            };

            const handleTouchStart = (event) => {
                touchStartRef.current = event.targetTouches[0].clientY;
            };

            const handleTouchMove = (event) => {
                touchEndRef.current = event.targetTouches[0].clientY;
            };

            const handleTouchEnd = () => {
                if (!touchStartRef.current || !touchEndRef.current) return;
                if (
                    touchStartRef.current > touchEndRef.current + 10 &&
                    currentQuestionIndex >= 1 &&
                    currentQuestionIndex < workPreferencesQuestionsLength &&
                    isCurrentQuestionAnswered()
                ) {
                    goToNextQuestion();
                } else if (
                    touchStartRef.current < touchEndRef.current - 10 &&
                    currentQuestionIndex !== 1
                ) {
                    goToPreviousQuestion();
                }
                touchStartRef.current = 0;
                touchEndRef.current = 0;
            };

            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('wheel', handleWheel);
            window.addEventListener('touchstart', handleTouchStart);
            window.addEventListener('touchmove', handleTouchMove);
            window.addEventListener('touchend', handleTouchEnd);

            return () => {
                window.removeEventListener('keydown', handleKeyDown);
                window.removeEventListener('wheel', handleWheel);
                window.removeEventListener('touchstart', handleTouchStart);
                window.removeEventListener('touchmove', handleTouchMove);
                window.removeEventListener('touchend', handleTouchEnd);
            };
        }
    }, [currentQuestionIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    // set new pages if the module is changed (custom module)
    useEffect(() => {
        if (pages[0].grid[0].id !== initialPages[0].grid[0].id) {
            setPages(initialPages);
            setCurrentPage(initialPages[0]);
            setFormData({
                id: questionnaire.id,
                questions: [],
                answers: [],
            });
            setQuestionnaireGridForm(buildForm(initialPages[0]));
        }
    }, [initialPages]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!showTimerTime && initialShowTimerTime) {
            setShowTimerTime(true);
        }
    }, [initialShowTimerTime, showTimerTime]);

    const renderIntroduction = () => {
        if (module.id.includes('mental')) {
            return (
                <MentalTestIntroduction
                    onNext={() => {
                        acceptIntroduction();
                        onRecordUserMedia('mental-test-1');
                    }}
                />
            );
        } else if (module.id.includes('culture')) {
            return (
                <CulturePreferencesIntro
                    isQuestionnaireOnlyScan={isQuestionnaireOnlyScan}
                    onNext={() => acceptIntroduction()}
                />
            );
        } else if (module.id === 'self-evaluation') {
            return (
                <DataCollectionIntro
                    onNext={() => acceptIntroduction()}
                    questionnaires={modules.map((module) => module.id)}
                />
            );
        } else {
            if (!module.introduction_layout) {
                // todo: review ui for this default introduction
                return (
                    <ModuleIntroductionView
                        buttonPlaceholder={t(
                            'QUESTIONNAIRE_VIEW.INTRODUCTION.CONTINUE_BUTTON',
                        )}
                        onNext={() => acceptIntroduction()}>
                        <div>
                            <h2>
                                {t('QUESTIONNAIRE_VIEW.INTRODUCTION.TITLE')}
                            </h2>
                            <IntroductionIconContainer>
                                <ReportIcon />
                            </IntroductionIconContainer>
                        </div>
                    </ModuleIntroductionView>
                );
            } else {
                return (
                    <CustomLayout
                        onNext={() => acceptIntroduction()}
                        layout={module.introduction_layout}
                    />
                );
            }
        }
    };

    const renderQuestionnaire = () => {
        return (
            <Container
                withTimer={showTimer}
                isWorkPreferencesQuestionnaire={isWorkPreferencesQuestionnaire}
                className={
                    isWorkPreferencesQuestionnaire && isMobileInLandscapeMode()
                        ? 'landscape'
                        : ''
                }>
                <GridContainer>
                    <QuestionnaireGrid
                        moduleType={module.id}
                        currentQuestionIndex={currentQuestionIndex}
                        setCurrentQuestionIndex={setCurrentQuestionIndex}
                        slideFadeInAndOut={slideFadeInAndOut}
                        setSlideFadeInAndOut={setSlideFadeInAndOut}
                        setTransitionDirection={setTransitionDirection}
                        transitionDirection={transitionDirection}
                        blinkAnimation={blinkAnimation}
                        setBlinkAnimation={setBlinkAnimation}
                        setForm={setQuestionnaireGridForm}
                        form={questionnaireGridForm}
                        page={currentPage}
                        onUpdate={onPageUpdated}
                    />
                </GridContainer>
                {!isWorkPreferencesQuestionnaire && (
                    <NavigationContainer
                        layout={navigationContainerLayout()}
                        isWorkPreferencesQuestionnaire={false}>
                        {/* Button: Backward on questionnaire pages (If enabled) */}
                        {showBackwardButton() && (
                            <ShadowButton
                                className="backward-navigation"
                                onClick={navigateBackward}>
                                {t(
                                    'QUESTIONNAIRE_VIEW.CONTROLS.PREVIOUS_BUTTON',
                                )}
                            </ShadowButton>
                        )}

                        {/* Button: Next on questionnaire pages */}
                        {!isLastPage() && (
                            <ShadowButton
                                className="forward-navigation"
                                disabled={shouldNextButtonBeDisabled()}
                                onClick={() => {
                                    OnResetRecordingChunk(
                                        `mental-test-${currentPage.id + 1}`,
                                    );
                                    navigateForward();
                                }}>
                                {t('QUESTIONNAIRE_VIEW.CONTROLS.NEXT_BUTTON')}
                            </ShadowButton>
                        )}

                        {/* Button: Finish on the last questionnaire page */}
                        {isLastPage() && (
                            <ShadowButton
                                className="submit-button"
                                disabled={shouldNextButtonBeDisabled()}
                                onClick={submitQuestionnaireAndStopVideo}>
                                {t(
                                    'QUESTIONNAIRE_VIEW.CONTROLS.FINISH_TASK_BUTTON',
                                )}
                            </ShadowButton>
                        )}
                    </NavigationContainer>
                )}
            </Container>
        );
    };

    if (!introductionAccepted) {
        return renderIntroduction();
    } else {
        return (
            <Layout
                isWorkPreferencesQuestionnaire={isWorkPreferencesQuestionnaire}
                id="layout-container">
                {showTimer && (
                    <TimerTopBar>
                        <TimerContainer beeping={timerBeeping}>
                            {showTimerTime && (
                                <div>
                                    <Timer
                                        initialTime={moduleTimer.time}
                                        direction="backward"
                                        checkpoints={[
                                            {
                                                time: 0,
                                                callback: onTimerEnd,
                                            },
                                            {
                                                time: 1000,
                                                callback: () => {
                                                    setTimerBeeping(false);
                                                },
                                            },
                                            {
                                                time: 16000,
                                                callback: () => {
                                                    setTimerBeeping(true);
                                                },
                                            },
                                        ]}>
                                        <Timer.Minutes
                                            formatValue={defaultTimerFormat}
                                        />
                                        <Timer.Seconds
                                            formatValue={(value) =>
                                                `${
                                                    value < 10
                                                        ? `0${value}`
                                                        : `${value}`
                                                }`
                                            }
                                        />
                                    </Timer>
                                </div>
                            )}
                        </TimerContainer>
                    </TimerTopBar>
                )}
                {renderQuestionnaire(showTimer, currentPage, formData, t)}
                {showEndTimerModal && (
                    <TimerAlertModal
                        time={10000}
                        onComplete={onModalTimerEnd}
                    />
                )}
                {isWorkPreferencesQuestionnaire && (
                    <NavigationContainer
                        layout="one-row"
                        isWorkPreferencesQuestionnaire={true}
                        className={
                            isWorkPreferencesQuestionnaire &&
                            isMobileInLandscapeMode()
                                ? 'landscape'
                                : ''
                        }>
                        <ButtonsWrapper>
                            <ArrowButtonWrapper>
                                <ShadowButton
                                    className="arrow-button"
                                    disabled={currentQuestionIndex === 1}
                                    onClick={goToPreviousQuestion}>
                                    <ChevronUpIcon color={'white'} size={28} />
                                </ShadowButton>
                                <ShadowButton
                                    className="arrow-button"
                                    disabled={
                                        currentQuestionIndex ===
                                            workPreferencesQuestionsLength ||
                                        !isCurrentQuestionAnswered()
                                    }
                                    onClick={goToNextQuestion}>
                                    <ChevronDownIcon
                                        color={'white'}
                                        size={28}
                                    />
                                </ShadowButton>
                            </ArrowButtonWrapper>

                            {currentPage.completed && (
                                <div>
                                    <ShadowButton
                                        className="submit-button"
                                        disabled={shouldNextButtonBeDisabled()}
                                        size={'small'}
                                        onClick={
                                            submitQuestionnaireAndStopVideo
                                        }>
                                        {t(
                                            'QUESTIONNAIRE_VIEW.CONTROLS.FINISH_TASK_BUTTON',
                                        )}
                                    </ShadowButton>
                                </div>
                            )}
                        </ButtonsWrapper>
                    </NavigationContainer>
                )}
            </Layout>
        );
    }
};

export default withMediaQuery(withTranslation()(QuestionnaireController));
