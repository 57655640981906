import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Container,
    GridContainer,
    Question,
    QuestionDescription,
    List,
    HorizontalList,
    MultipleChoiceList,
    MultipleOptionsList,
    MultipleOptionsItemContainer,
    DropdownContainer,
    TextFieldContainer,
    MultipleChoiceRadioLabel,
    QuestionnaireTitle,
    QuestionSection,
    AnswerWrapper, RadioList,
} from './QuestionnaireGrid.style';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import debounce from 'lodash/debounce';
import update from 'immutability-helper';
import DOMPurify from 'dompurify';
import { isMobileInLandscapeMode } from '../../../utils/landscapeDetector';
import { ArrowRightIcon } from 'evergreen-ui';
import { TextareaAutosize } from '@material-ui/core';

export const QuestionContainer = (props) => {
    const { question, questionIndex, description, moduleType, children } =
        props;
    const isWorkPreferencesQuestionnaire = moduleType.includes('culture');

    return (
        <GridContainer
            isWorkPreferencesQuestionnaire={isWorkPreferencesQuestionnaire}
            className={
                isWorkPreferencesQuestionnaire && isMobileInLandscapeMode()
                    ? 'landscape'
                    : ''
            }>
            {isWorkPreferencesQuestionnaire && (
                <Question isWorkPreferencesQuestionnaire={true}>
                    <span className="question-number">
                        {questionIndex ? `${questionIndex} ` : ``}
                        <ArrowRightIcon size={12} />
                    </span>
                    {question}
                </Question>
            )}
            {!isWorkPreferencesQuestionnaire && (
                <Question isWorkPreferencesQuestionnaire={false}>
                    {questionIndex ? `${questionIndex}. ` : ` `}
                    <span
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(question, {
                                ADD_ATTR: ["target"]
                            }),
                        }}
                    />
                </Question>
            )}
            {!!description && (
                <QuestionDescription>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(description),
                        }}
                    />
                </QuestionDescription>
            )}
            <QuestionSection
                className={
                    isWorkPreferencesQuestionnaire && isMobileInLandscapeMode()
                        ? 'landscape'
                        : ''
                }>
                {children}
            </QuestionSection>
        </GridContainer>
    );
};

const QuestionnaireGrid = (props) => {
    const {
        page,
        moduleType,
        currentQuestionIndex,
        transitionDirection,
        setTransitionDirection,
        slideFadeInAndOut,
        setSlideFadeInAndOut,
        setCurrentQuestionIndex,
        blinkAnimation,
        setBlinkAnimation,
        onUpdate,
        form,
        setForm,
    } = props;
    const { t } = useTranslation();

    const handleWrapperClick = (
        questionIndex,
        questionId,
        optionId,
        questionsLength,
    ) => {
        const simulatedEvent = {
            target: { value: optionId },
        };
        if (
            isWorkPreferencesQuestionnaire &&
            currentQuestionIndex < questionsLength
        ) {
            setBlinkAnimation(true);
            setTimeout(() => {
                setTransitionDirection('forward');
                setSlideFadeInAndOut('exiting');
                setTimeout(() => {
                    setCurrentQuestionIndex(questionIndex + 1);
                    setSlideFadeInAndOut('entering');
                    setBlinkAnimation(false);
                }, 500);
            }, 1000);
        }
        onAnswerChange(questionIndex, questionId, simulatedEvent);
    };

    const onAnswerChange = (index, id, event) => {
        const value = event.target.value;
        const updatedForm = {
            ...form,
            [id]: value,
        };
        setForm(updatedForm);
        handleOutputEvents(updatedForm);
    };

    const handleOutputEvents = debounce((updatedForm) => {
        let isValid;
        if (moduleType === 'custom-questionnaire') {
            const validKeys = page.grid
                .filter(
                    (gridItem) => gridItem.type !== 'multiple-choice/checkbox',
                )
                .map((gridItem) => gridItem.id);
            isValid = validKeys.every((key) => {
                const value = updatedForm[key];
                return value !== null && value !== '';
            });
        } else {
            isValid = Object.keys(updatedForm).every(
                (key) =>
                    updatedForm[key] && typeof updatedForm[key] !== 'undefined',
            );
        }
        onUpdate(updatedForm, isValid);
    }, 200);

    const onCheckboxChange = (questionId, optionId, event, isChecked) => {
        if (isChecked) {
            const updatedForm = update(form, {
                [questionId]: {
                    $unset: [optionId],
                },
            });
            setForm(updatedForm);
            handleOutputEvents(updatedForm);
        } else {
            const updatedForm = update(form, {
                [questionId]: {
                    $set: {
                        ...(form[questionId] || {}),
                        [optionId]: event.target.checked,
                    },
                },
            });
            setForm(updatedForm);
            handleOutputEvents(updatedForm);
        }
    };

    const renderWorkPreferences = () => {
        return page.grid.map((item) => {
            const gridIdentifier = `form-grid/${item.id}/${item.type}`;
            if (
                item.type === 'statement/multiple-choice-grid' &&
                item.questions.length + 1 > currentQuestionIndex
            ) {
                return item.questions.map((question, questionIndex) => {
                    if (question.questionIndex === currentQuestionIndex) {
                        return (
                            <div key={gridIdentifier + questionIndex}>
                                <QuestionContainer
                                    question={question.statement}
                                    moduleType={moduleType}
                                    questionIndex={question.questionIndex}>
                                    <MultipleChoiceList>
                                        <MultipleOptionsItemContainer
                                            isWorkPreferencesQuestionnaire={
                                                true
                                            }
                                            className={
                                                isMobileInLandscapeMode()
                                                    ? 'is-landscape'
                                                    : ''
                                            }
                                            key={`question-${question.questionIndex}`}
                                            id={`#question-${question.questionIndex}`}>
                                            <MultipleOptionsList
                                                isWorkPreferencesQuestionnaire={
                                                    true
                                                }>
                                                {question.options.map(
                                                    (option, index) => (
                                                        <AnswerWrapper
                                                            key={`option-${question.questionIndex}-${index}`}
                                                            className={
                                                                isMobileInLandscapeMode()
                                                                    ? 'landscape'
                                                                    : ''
                                                            }
                                                            isWorkPreferencesQuestionnaire={
                                                                true
                                                            }
                                                            checked={
                                                                form[
                                                                    question.id
                                                                ] === option.id
                                                            }
                                                            onClick={() =>
                                                                handleWrapperClick(
                                                                    question.questionIndex,
                                                                    question.id,
                                                                    option.id,
                                                                    item
                                                                        .questions
                                                                        .length,
                                                                )
                                                            }>
                                                            <MultipleChoiceRadioLabel
                                                                isWorkPreferencesQuestionnaire={
                                                                    true
                                                                }>
                                                                <Radio
                                                                    id={
                                                                        option.id
                                                                    }
                                                                    value={
                                                                        option.id
                                                                    }
                                                                    checked={
                                                                        form[
                                                                            question
                                                                                .id
                                                                        ] ===
                                                                        option.id
                                                                    }
                                                                    style={{
                                                                        padding: 0,
                                                                        color:
                                                                            form[
                                                                                question
                                                                                    .id
                                                                            ] ===
                                                                            option.id
                                                                                ? 'white'
                                                                                : 'rgba(0, 0, 0, 0.54)',
                                                                        animation:
                                                                            blinkAnimation &&
                                                                            form[
                                                                                question
                                                                                    .id
                                                                            ] ===
                                                                                option.id
                                                                                ? 'blink 0.5s ease-out'
                                                                                : '',
                                                                        animationIterationCount:
                                                                            blinkAnimation &&
                                                                            form[
                                                                                question
                                                                                    .id
                                                                            ] ===
                                                                                option.id
                                                                                ? '2'
                                                                                : '0',
                                                                    }}
                                                                />
                                                                <span>
                                                                    {
                                                                        option.text
                                                                    }
                                                                </span>
                                                            </MultipleChoiceRadioLabel>
                                                        </AnswerWrapper>
                                                    ),
                                                )}
                                            </MultipleOptionsList>
                                        </MultipleOptionsItemContainer>
                                    </MultipleChoiceList>
                                </QuestionContainer>
                            </div>
                        );
                    }
                    return null;
                });
            }
            return null;
        });
    };

    const renderGridTypes = () => {
        return page.grid.map((item) => {
            const gridIdentifier = `form-grid/${item.id}/${item.type}`;
            switch (item.type) {
                case 'statement/multiple-choice-grid': {
                    const questions = item.questions.map((question) => {
                        const answers = question.options.map(
                            (option, index) => (
                                <AnswerWrapper
                                    key={`option-${question.questionIndex}-${index}`}
                                    checked={form[question.id] === option.id}
                                    onClick={() =>
                                        handleWrapperClick(
                                            question.questionIndex,
                                            question.id,
                                            option.id,
                                        )
                                    }>
                                    <MultipleChoiceRadioLabel>
                                        <Radio
                                            id={option.id}
                                            value={option.id}
                                            checked={
                                                form[question.id] === option.id
                                            }
                                            style={{
                                                padding: 0,
                                                color:
                                                    form[question.id] ===
                                                    option.id
                                                        ? '#00A5AE'
                                                        : 'rgba(0, 0, 0, 0.54)',
                                            }}
                                        />
                                        <span>{option.text}</span>
                                    </MultipleChoiceRadioLabel>
                                </AnswerWrapper>
                            ),
                        );
                        return (
                            <MultipleOptionsItemContainer
                                key={`question-${question.questionIndex}`}
                                id={`#question-${question.questionIndex}`}>
                                <MultipleOptionsList
                                    isWorkPreferencesQuestionnaire={false}>
                                    {answers}
                                </MultipleOptionsList>
                            </MultipleOptionsItemContainer>
                        );
                    });
                    return (
                        <div key={gridIdentifier}>
                            {item.explanation && (
                                <QuestionDescription>
                                    <span>{item.explanation}</span>
                                </QuestionDescription>
                            )}
                            <QuestionContainer
                                question={item.statement + ':'}
                                moduleType={moduleType}
                                description={item.description}>
                                <MultipleChoiceList>
                                    {questions}
                                </MultipleChoiceList>
                            </QuestionContainer>
                        </div>
                    );
                }

                case 'short-question': {
                    return (
                        <QuestionContainer
                            key={gridIdentifier}
                            questionIndex={item.questionIndex}
                            moduleType={moduleType}
                            question={item.question}
                            description={item.description}>
                            <TextFieldContainer>
                                <TextField
                                    id="standard-bare"
                                    style={{ width: '100%' }}
                                    placeholder={t(
                                        'FEEDBACK_QUESTIONNAIRE.GRID.QUESTION_PLACEHOLDER',
                                    )}
                                    value={form[item.id] ? form[item.id] : ''}
                                    onChange={(event) =>
                                        onAnswerChange(
                                            item.questionIndex,
                                            item.id,
                                            event,
                                            true,
                                        )
                                    }
                                    //variant="outlined"
                                />
                            </TextFieldContainer>
                        </QuestionContainer>
                    );
                }

                case 'text-area-question': {
                    return (
                        <QuestionContainer
                            key={gridIdentifier}
                            questionIndex={item.questionIndex}
                            moduleType={moduleType}
                            question={item.question}
                            description={item.description}>
                            <TextFieldContainer>
                                <TextareaAutosize
                                    id="standard-bare"
                                    minRows={5}
                                    style={{ width: '100%' }}
                                    placeholder={t(
                                        'FEEDBACK_QUESTIONNAIRE.GRID.QUESTION_PLACEHOLDER',
                                    )}
                                    value={form[item.id] ? form[item.id] : ''}
                                    onChange={(event) =>
                                        onAnswerChange(
                                            item.questionIndex,
                                            item.id,
                                            event,
                                            true,
                                        )
                                    }
                                />
                            </TextFieldContainer>
                        </QuestionContainer>
                    );
                }

                case 'long-question': {
                    return (
                        <QuestionContainer
                            key={gridIdentifier}
                            questionIndex={item.questionIndex}
                            question={item.question}
                            moduleType={moduleType}
                            description={item.description}>
                            <TextFieldContainer>
                                <TextField
                                    id="standard-bare"
                                    style={{ width: '100%' }}
                                    multiline
                                    minRows="6"
                                    maxRows="10"
                                    placeholder={t(
                                        'FEEDBACK_QUESTIONNAIRE.GRID.QUESTION_PLACEHOLDER',
                                    )}
                                    value={form[item.id] ? form[item.id] : ''}
                                    onChange={(event) =>
                                        onAnswerChange(
                                            item.questionIndex,
                                            item.id,
                                            event,
                                            true,
                                        )
                                    }
                                    variant="outlined"
                                />
                            </TextFieldContainer>
                        </QuestionContainer>
                    );
                }

                case 'likert-scale': {
                    const scaleRange = item.scaleValues.map((scale, index) => {
                        const scaleValue = scale.toString();
                        return (
                            <li
                                className="likert-scale-option"
                                key={`likert-scale-${item.id}-${scale}`}>
                                <Radio
                                    id={`likert-scale-${item.id}-${scale}`}
                                    value={scaleValue}
                                    checked={form[item.id] === scaleValue}
                                    onChange={(event) =>
                                        onAnswerChange(index, item.id, event)
                                    }
                                />
                                <label
                                    htmlFor={`likert-scale-${item.id}-${scale}`}>
                                    {item.labels.length ===
                                    item.scaleValues.length
                                        ? item.labels[index]
                                        : scaleValue}
                                </label>
                            </li>
                        );
                    });
                    return (
                        <QuestionContainer
                            key={gridIdentifier}
                            className={`likert-scale`}
                            questionIndex={item.questionIndex}
                            question={item.question}
                            moduleType={moduleType}
                            description={item.description}>
                            <TextFieldContainer>
                                <HorizontalList>
                                    {item.labels.length !==
                                        item.scaleValues.length && (
                                        <li className="likert-scale-label">
                                            {item.labels[0]}
                                        </li>
                                    )}
                                    {scaleRange}
                                    {item.labels.length !==
                                        item.scaleValues.length && (
                                        <li className="likert-scale-label">
                                            {
                                                item.labels[
                                                    item.labels.length - 1
                                                ]
                                            }
                                        </li>
                                    )}
                                </HorizontalList>
                            </TextFieldContainer>
                        </QuestionContainer>
                    );
                }
                case 'multiple-choice/radio': {
                    const options = item.options.map((option, index) => {
                        const optionUniqueId = `${item.id}-${option.id}`;
                        return (
                            <RadioList key={`multiple-radio-${optionUniqueId}`}>
                                <Radio
                                    id={optionUniqueId}
                                    className={'radio-button'}
                                    value={option.id}
                                    checked={form[item.id] === option.id}
                                    onChange={(event) =>
                                        onAnswerChange(index, item.id, event)
                                    }
                                />
                                <label htmlFor={optionUniqueId}>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                option.text,
                                            ),
                                        }}
                                    />
                                </label>
                            </RadioList>
                        );
                    });
                    return (
                        <QuestionContainer
                            key={gridIdentifier}
                            questionIndex={item.questionIndex}
                            question={item.question}
                            moduleType={moduleType}
                            description={item.description}>
                            <List>{options}</List>
                        </QuestionContainer>
                    );
                }
                case 'multiple-choice/checkbox': {
                    const options = item.options.map((option) => {
                        const optionUniqueId = `${item.id}-${option.id}`;
                        const isChecked =
                            form[item.id] && form[item.id][option.id]
                                ? !!form[item.id][option.id]
                                : false;
                        return (
                            <li key={`multiple-checkbox-${optionUniqueId}`}>
                                <Checkbox
                                    id={optionUniqueId}
                                    value={option.id}
                                    checked={isChecked}
                                    onChange={(event) =>
                                        onCheckboxChange(
                                            item.id,
                                            option.id,
                                            event,
                                            isChecked,
                                        )
                                    }
                                />
                                <label htmlFor={optionUniqueId}>
                                    {option.text}
                                </label>
                            </li>
                        );
                    });
                    return (
                        <QuestionContainer
                            key={gridIdentifier}
                            questionIndex={item.questionIndex}
                            question={item.question}
                            moduleType={moduleType}
                            description={item.description}>
                            <List>{options}</List>
                        </QuestionContainer>
                    );
                }
                case 'dropdown-selection': {
                    const options = item.options.map((option) => {
                        const optionId = `${item.id}:${option.id}`;
                        return (
                            <MenuItem key={optionId} value={option.id}>
                                {option.text}
                            </MenuItem>
                        );
                    });
                    return (
                        <QuestionContainer
                            key={gridIdentifier}
                            questionIndex={item.questionIndex}
                            question={item.question}
                            moduleType={moduleType}
                            description={item.description}>
                            <DropdownContainer>
                                <Select
                                    value={form[item.id]}
                                    onChange={(event) =>
                                        onAnswerChange(
                                            item.questionIndex,
                                            item.id,
                                            event,
                                        )
                                    }
                                    autoWidth={false}
                                    id={item.id}
                                    name={item.id}
                                    displayEmpty>
                                    <MenuItem value="" disabled>
                                        {t(
                                            'QUESTIONNAIRE_VIEW.GRID.SELECTOR_PLACEHOLDER',
                                        )}
                                    </MenuItem>
                                    {options}
                                </Select>
                            </DropdownContainer>
                        </QuestionContainer>
                    );
                }
                default:
                    return '';
            }
        });
    };

    const isWorkPreferencesQuestionnaire = moduleType.includes('culture');

    return (
        <Container
            className={
                isWorkPreferencesQuestionnaire
                    ? `${slideFadeInAndOut + ' ' + transitionDirection}`
                    : ''
            }>
            {page.title && (
                <QuestionnaireTitle>{page.title}</QuestionnaireTitle>
            )}
            {isWorkPreferencesQuestionnaire
                ? renderWorkPreferences()
                : renderGridTypes()}
        </Container>
    );
};

export default QuestionnaireGrid;
