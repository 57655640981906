import { store } from '../store';
import { selectConfigState } from '../store/config/config.selectors.js';
import { LOGGER_HOST, NEUROLYTICS_API } from 'utils/environment-variables';
import { auth0Client } from 'Auth';

import axios from 'axios';
import * as Sentry from '@sentry/browser';

export class NeurolyticsAPI {
    async registerUser(targetGroupId, user) {
        try {
            const response = await NeurolyticsAPI.apiRequest(
                `register/${targetGroupId}`,
                'POST',
                {
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                    password: user.password,
                    integrations: user.integrations,
                },
                'application/json',
                true,
            );
            return {
                companySlug: response.data.company_slug,
                assessmentId: response.data.assessment_id,
                invitationId: response.data.invitation_id,
                email: response.data.email,
                emailVerified: response.data.email_verified,
                status: response.data.status,
                error: null,
            };
        } catch (error) {
            const errorMessage = `registerUser API Request failed: ${error}.`;
            Sentry.captureMessage(errorMessage);

            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                const errorMessage = error.response.data.message.includes('|')
                    ? error.response.data.message.split('|')[0]
                    : error.response.data.message;

                let errorCode;
                let errorParam;

                switch (errorMessage) {
                    case 'InvalidRequest':
                    case 'InvalidUserField':
                        errorCode = 'INVALID_USER_FIELD';
                        break;
                    case 'TargetGroupNotFound':
                        errorCode = 'SCAN_NOT_FOUND';
                        break;
                    case 'TargetGroupNotPublic':
                    case 'AssessmentNotFound':
                        errorCode = 'SCAN_NOT_PUBLIC';
                        errorParam = 'Neurolytics'; //todo use company name
                        break;
                    case 'WeakPassword':
                        errorCode = 'WEAK_PASSWORD';
                        break;
                    case 'InvalidUserEmail':
                        errorCode = 'INVALID_USER_EMAIL';
                        errorParam = error.response.data.message.split('|')[1];
                        break;
                    case 'FailureToCreateUser':
                        errorParam = 'Neurolytics'; //todo use company name
                        break;
                    case 'InternalUserError':
                    case 'InvalidParticipantSchema':
                    case 'UserNotFound':
                    case 'UnexpectedError':
                    case 'FailureToUpdateParticipantAppMeta':
                    case 'FailureToUpdateTargetGroup':
                    default:
                        errorCode = 'FAILURE_TO_CREATE_USER';
                        errorParam = 'Neurolytics'; //todo use company name
                }
                console.log('error api', errorCode, errorParam);
                return {
                    error: {
                        errorCode,
                        errorParam,
                    },
                };
            }
        }
    }

    async fetchCompanyApi(companySlug) {
        try {
            const response = await NeurolyticsAPI.apiRequest(
                `companies/slug/${companySlug}`,
                'GET',
                {},
                'application/json',
                true,
            );
            return {
                name: response.data.name,
                // country: response.data.country,
                logoUrl: response.data.logoUrl,
                language: 'en-US', // todo: update this after adding language to companies on backend
            };
        } catch (error) {
            const errorMessage = `fetchCompany API Request failed using slug: ${companySlug}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async fetchConfigApi(assessmentId, invitationId) {
        try {
            const response = await NeurolyticsAPI.apiRequest(
                `assessments/${assessmentId}/${invitationId}/config`,
                'GET',
            );
            return {
                companySlug: response.data.company_slug,
                companyName: response.data.company_name,
                companyLogoUrl: response.data.company_logo_url,
                targetGroupName: response.data.target_group_name,
                config: response.data.config,
                modules: response.data.modules,
                language: response.data.language,
                assessmentName: response.data.assessment_name,
                isCompleted: response.data.is_completed,
                groupRelations: response.data.group_relations,
                description: response.data.description
                    ? response.data.description
                    : null,
                autoAnalysis:
                    typeof response.data.auto_analysis !== 'undefined'
                        ? response.data.auto_analysis
                        : true,
                dataCollection: response.data.data_collection
                    ? response.data.data_collection
                    : false,
            };
        } catch (error) {
            const errorMessage = `fetchConfig API Request failed using assessmentId: ${assessmentId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }
    async fetchPublicConfigApi(targetGroupId) {
        try {
            const response = await NeurolyticsAPI.apiRequest(
                `group_config/${targetGroupId}`,
                'GET',
                {},
                'application/json',
                true,
            );

            return {
                name: response.data.name,
                language: response.data.lang,
            };
        } catch (error) {
            const errorMessage = `fetchPublicConfig API Request failed using groupId: ${targetGroupId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async requestUpload(invitationId, key, contentType) {
        const { scans } = selectConfigState(store.getState());
        const assessmentId = scans[invitationId].assessmentId;

        try {
            const response = await NeurolyticsAPI.apiRequest(
                `assessments/${assessmentId}/request-upload`,
                'POST',
                {
                    key,
                    content_type: contentType,
                },
            );
            if (response.status === 200 && response.data && response.data.url) {
                return response.data.url;
            }
        } catch (error) {
            const errorMessage = `requestUpload API Request failed: Failed to read pre-signed URL from server response.`;
            Sentry.captureMessage(errorMessage);
            throw new Error(errorMessage);
        }

        //  else {
        //     const errorMessage = `requestUpload API Request failed: Failed to read pre-signed URL from server response.`;
        //     Sentry.captureMessage(errorMessage);
        //     throw new Error(errorMessage);
        // }
    }
    async sendLog(userId, groupId, message) {
        try {
            await NeurolyticsAPI.apiRequest(
                `https://${LOGGER_HOST}/log/scan/${groupId}/${userId}`,
                'POST',
                {
                    message: message,
                },
            );
        } catch (error) {
            const errorMessage = `failed to send log with message: ${message} and the error is ${error}`;
            throw new Error(errorMessage);
        }
    }
    async uploadFile(params) {
        try {
            // request S3 pre-signed URL
            const presignedUrl = await this.requestUpload(
                params.invitationId,
                params.fileKey,
                params.contentType,
            );

            if (presignedUrl) {
                // Prepare the file (stringify non-video files)
                let fileToUpload;
                if (params.fileType === 'video') {
                    fileToUpload = await new File(
                        [params.file],
                        `${params.fileId}.${params.fileType}`,
                        {
                            type: 'video/webm',
                        },
                    );
                } else {
                    const blob = new Blob(
                        [JSON.stringify(params.file, null, 2)],
                        {
                            type: 'application/json',
                        },
                    );
                    fileToUpload = new File([blob], params.fileName);
                }

                return await NeurolyticsAPI.apiRequest(
                    presignedUrl,
                    'PUT',
                    fileToUpload,
                    params.contentType,
                    true,
                );
            }
        } catch (error) {
            throw error;
        }
    }

    async finishScanApiCall(assessmentId, invitationId) {
        const { scans } = selectConfigState(store.getState());
        if (!scans[invitationId].autoAnalysis) {
            return;
        }

        try {
            await NeurolyticsAPI.apiRequest(
                `scans/${assessmentId}/${invitationId}/completed`,
                'POST',
            );
        } catch (error) {
            const errorMessage = `Scan completion API Request failed using assessmentId: ${assessmentId} and invitationId: ${invitationId}`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    static async apiRequest(
        endpoint,
        method = 'GET',
        data = {},
        contentType = 'application/json',
        isPublic = false,
    ) {
        try {
            const config = await NeurolyticsAPI.axiosConfig(
                endpoint,
                method,
                data,
                contentType,
                isPublic,
            );
            return await axios(config);
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log('error.response.data', error.response.data);
                // console.log('error.response.status', error.response.status);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                // console.log('error.request', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
            }
            // console.log('error.config', error.config);
            throw error;
        }
    }

    static async axiosConfig(endpoint, method, data, contentType, isPublic) {
        const isNeurolyticsRequest = !endpoint.startsWith('http');
        try {
            let headers = {
                'Content-Type': contentType,
            };
            if (!isPublic) {
                const idToken = await NeurolyticsAPI.getAuthToken();
                if (idToken) {
                    headers = {
                        ...headers,
                        Authorization: `Bearer ${idToken}`,
                    };
                }
            }
            return {
                method,
                url: isNeurolyticsRequest
                    ? `${NEUROLYTICS_API}/${endpoint}`
                    : endpoint,
                headers,
                data,
            };
        } catch (error) {
            throw error;
        }
    }

    static async getAuthToken() {
        if (auth0Client) {
            try {
                return await auth0Client.getTokenSilently();
            } catch (error) {
                throw error;
            }
        } else {
            return null;
        }
    }
}

export default new NeurolyticsAPI();
