import styled, { keyframes, css } from 'styled-components';
import { breakpoints } from '../../../App.style';

const timerTopBarHeight = '60px';

export const Layout = styled.div`
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.backgroundColor};
    align-items: center;
    height: ${(props) => (props.isWorkPreferencesQuestionnaire ? '100vh' : 'auto')};
    overflow-x: ${(props) => (props.isWorkPreferencesQuestionnaire ? 'hidden' : 'auto')};
    @media only screen and (min-width: ${breakpoints.tablet}) {
        padding: 0 2vw;
    }
    @media only screen and (min-width: ${breakpoints.desktop}) {
        padding: 0 4vw;
    }
`;
export const Header = styled.div`
    width: 100%;
    min-height: 20%;
    background: ${(props) => props.theme.backgroundColor};
    display: flex;
    align-items: center;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.isWorkPreferencesQuestionnaire ? '50%' : '80%')};
    height: 100%;
    overflow-y: ${(props) => props.isWorkPreferencesQuestionnaire && 'hidden'};
    padding-top: ${(props) => (props.withTimer ? timerTopBarHeight : '0')};
    ${(props) =>
        props.isWorkPreferencesQuestionnaire
            ? `
        align-items:center;
        justify-content:center;
            background-color: white;
            padding: 0 10%;
        @media only screen and (max-width: ${breakpoints.desktop}) {
            width: 60%;
        }
        @media only screen and (max-width: ${breakpoints.mobile}) {
            width: 80%;
        }
        @media only screen and (max-height: 500px) {
            min-height: 500px;
        }
      `
            : ''};

    &.landscape {
        width: 80%;
        padding: 24px 10%;
    }
`;

export const GridContainer = styled.div`
    width: 100%;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    width: 100%;
    @media only screen and (max-width: 220px) {
        flex-direction: column;
        gap: 8px;
    }
    @media only screen and (max-width: ${breakpoints.smallMobile}) {
        padding-bottom: 16px;
    }
`;

export const ArrowButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    .arrow-button {
        width: 50px;
        height: 42px;
        padding: 0;
        margin: 0;
        border-radius: 4px;
        &:hover {
            opacity: 0.7;
        }
        &:active {
            opacity: 1;
        }
    }
`;

export const NavigationContainer = styled.div`
    width: 100%;
    padding-top: 20px;
    padding-bottom: 40px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: ${(props) =>
        props.layout === 'two-row' ? 'space-between' : 'flex-end'};
    gap: 1em;
    ${(props) =>
        props.isWorkPreferencesQuestionnaire
            ? `
        justify-content:space-between;
            padding-bottom: 16px;
      `
            : ''};
    @media only screen and (max-width: ${breakpoints.tablet}) {
        width: 100%;
    }
    .button-wrapper {
        margin: 0 0 0 auto;
    }
    button {
        max-width: 300px;
        width: ${(props) => (props.isWorkPreferencesQuestionnaire ? '100%' : '80%')};
        ${(props) =>
            props.isWorkPreferencesQuestionnaire
                ? `
                    padding: 0 60px;
                    @media only screen and (max-width: ${breakpoints.mobile}) {
                        padding: 0 40px;
                    }
                    @media only screen and (max-width: ${breakpoints.mobile}) {
                        padding: 0 25px;
                    }
                `
                : ''};
    }
    @media only screen and (max-width: ${breakpoints.mobile}) {
        padding-bottom: 20px;
    }
    .forward-navigation-text {
        font-size: 0.8em;
        color: #a0acb7;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
    }
    @media only screen and (max-width: 300px) {
        padding-bottom: 0;
        padding-top: 10px;
    }
    &.landscape {
        padding-top: 0;
    }
`;

export const IntroductionIconContainer = styled.div`
    margin-top: 50px;
    svg {
        width: 100px;
        margin-left: 100px;
    }
`;

export const TimerTopBar = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: ${timerTopBarHeight};
    background: ${(props) => props.theme.mainColor};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;

export const TimerContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.textColor};
    font-weight: bold;
    ${(props) => (props.beeping ? beepingAnimation : 'animation: none;')}
    background: #fff;
    padding: 5px 30px;
    border-radius: 25px;
`;

const timerBeeping = keyframes`
    from {
        color: #fff;
    }

    50% {
        color: red;
    }

    to {
        color: #fff;
    }
`;

const beepingAnimation = css`
    animation: ${timerBeeping} 1s linear infinite;
`;
