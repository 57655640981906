import React from 'react';
import {
    Container,
    ContentLayout,
    Image,
    Message,
    ButtonContainer,
    Footer,
} from './CustomLayout.style';
import { withTranslation } from 'react-i18next';
import ShadowButton from 'components/shared/ShadowButton/ShadowButton';
import questionIcon from 'assets/images/questions_icon.png';

export function CustomLayout(props) {
    const { t, layout, onNext } = props;

    const renderWithLinks = (items, uniqueKey) => {
        return items.map((item, index) => {
            if (item.href) {
                return (
                  <a
                    key={`${uniqueKey}-link-${index}`}
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="layout-link"
                  >
                      {t(item.text)}
                  </a>
                );
            }
            return (
              <span key={`${uniqueKey}-text-${index}`} className="layout-text">
                    {t(item.text)}
                </span>
            );
        });
    };

    const renderTitle = (item, uniqueKey) => {
        return (
            <h1 key={uniqueKey} className="layout-title">
                {t(item.value)}
            </h1>
        );
    };

    const renderSubtitle = (item, uniqueKey) => {
        return (
            <h2 key={uniqueKey} className="layout-subtitle">
                {t(item.value)}
            </h2>
        );
    };

    const renderParagraph = (item, uniqueKey) => {
        return (
            <p key={uniqueKey} className="layout-paragraph">
                {renderWithLinks(item.value, uniqueKey)}
            </p>
        );
    };

    const renderText = (item, uniqueKey) => {
        return (
            <span key={uniqueKey} className="layout-text">
                {renderWithLinks(item.value, uniqueKey)}
            </span>
        );
    };

    const renderList = (item, uniqueKey) => {
        return (
            <ul key={uniqueKey} className="layout-list">
                {item.value.map((listItem, listIndex) => (
                    <li key={`${uniqueKey}-${listIndex}`}>
                        {Array.isArray(listItem)
                            ? renderWithLinks(
                                  listItem,
                                  `${uniqueKey}-${listIndex}`,
                              )
                            : listItem}
                    </li>
                ))}
            </ul>
        );
    };

    const renderLayout = (items, parentKey = 0) => {
        return items.map((layoutItem, index) => {
            const createId = (item) => `${parentKey}-${item}-${index}`;
            switch (layoutItem.type) {
                case 'title': {
                    return renderTitle(layoutItem, createId('title'));
                }
                case 'subtitle': {
                    return renderSubtitle(layoutItem, createId('subtitle'));
                }
                case 'paragraph': {
                    return renderParagraph(layoutItem, createId('paragraph'));
                }
                case 'text': {
                    return renderText(layoutItem, createId('text'));
                }
                case 'list': {
                    return renderList(layoutItem, createId('list'));
                }
                default:
                    return null;
            }
        });
    };
    return (
        <Container>
            <ContentLayout>
                <Image src={questionIcon} alt="questions icons" />
                <Message>{renderLayout(layout)}</Message>
            </ContentLayout>
            <Footer>
                <ButtonContainer>
                    <ShadowButton onClick={onNext}>
                        {t('QUESTIONNAIRE_VIEW.INTRODUCTION.CONTINUE_BUTTON')}
                    </ShadowButton>
                </ButtonContainer>
            </Footer>
        </Container>
    );
}

export default withTranslation()(CustomLayout);
