import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';
import {
    Container,
    Header,
    Message,
    Footer,
    TextContainer,
    CountDown,
} from './AssessmentEndingView.style';

import neurolyticsLogo from 'assets/images/neurolytics-logo.png';
import poweredByNeurolyticsLogo from 'assets/images/powered-by-neurolytics.png';
import CompanyLogo from '../../components/shared/CompanyLogo/CompanyLogo';
import withMediaQuery from '../../hoc/MediaQuery/withMediaQuery';
import SuccessAnimation from '../../components/SuccessAnimation/SuccessAnimation';
import { useLocation, useNavigate } from 'react-router-dom';

function AssessmentEndingView(props) {
    const {
        modules,
        companySlug,
        onScanFinished,
        isMobileSize,
        groupRelations,
    } = props;
    const { companies } = useSelector((state) => state.config);
    const { logoUrl } = companies[companySlug];
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const nextScanId = query.get('nextScanId');
    const nextInvitationId = query.get('nextInvitationId');
    const [homePageRedirection, setHomePageRedirection] = useState(false);
    const [seconds, setSeconds] = useState(10);

    useEffect(() => {
        onScanFinished();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            groupRelations &&
            groupRelations.type === 'base' &&
            nextScanId &&
            nextInvitationId
        ) {
            navigate(`/${companySlug}/${nextScanId}/${nextInvitationId}`);
            return;
        }
        if (
            groupRelations &&
            groupRelations.type === 'base' &&
            (!nextScanId || !nextInvitationId)
        ) {
            setHomePageRedirection(true);
        }
    }, [nextScanId, nextInvitationId, navigate, companySlug]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (homePageRedirection && seconds <= 0) {
            navigate('/');
        }
    }, [seconds, homePageRedirection, navigate]);

    useEffect(() => {
        if (homePageRedirection) {
            const interval = setInterval(() => {
                setSeconds((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [homePageRedirection]);

    return (
        <>
            {homePageRedirection && (
                <>
                    <Container>
                        <Header>
                            <CompanyLogo
                                logoUrl={logoUrl ? logoUrl : neurolyticsLogo}
                            />
                        </Header>
                        <TextContainer>
                            <Message>
                                <h3>
                                    <Trans
                                        t={props.t}
                                        i18nKey="ENDING_VIEW.NEXT_SCAN_MESSAGE"
                                    />
                                </h3>
                                <CountDown>
                                    <h2>{seconds}s</h2>
                                    <a href="/">
                                        <Trans
                                            t={props.t}
                                            i18nKey="ENDING_VIEW.REDIRECT_NOW"
                                        />
                                    </a>
                                </CountDown>
                            </Message>
                        </TextContainer>
                        {isMobileSize && <div className="spacer"></div>}
                        <Footer>
                            <img
                                src={poweredByNeurolyticsLogo}
                                alt="Neurolytics Logo"
                            />
                        </Footer>
                    </Container>
                </>
            )}
            {!homePageRedirection && (
                <>
                    <SuccessAnimation />
                    <Container>
                        <Header>
                            <CompanyLogo
                                logoUrl={logoUrl ? logoUrl : neurolyticsLogo}
                            />
                        </Header>
                        <TextContainer>
                            <Message>
                                <h1>{props.t('ENDING_VIEW.TITLE')}</h1>
                                <h3>
                                    <Trans
                                        t={props.t}
                                        i18nKey="ENDING_VIEW.MESSAGE_1"
                                    />
                                </h3>
                                {modules[modules.length - 1].customText1 && (
                                    <h3>
                                        {' '}
                                        {
                                            modules[modules.length - 1]
                                                .customText1
                                        }
                                    </h3>
                                )}
                                {modules[modules.length - 1].customText2 && (
                                    <h3>
                                        {' '}
                                        {
                                            modules[modules.length - 1]
                                                .customText3
                                        }
                                    </h3>
                                )}
                                <h3>
                                    <Trans
                                        t={props.t}
                                        i18nKey="ENDING_VIEW.MESSAGE_2"
                                    />
                                </h3>
                                <h3>
                                    <Trans
                                        t={props.t}
                                        i18nKey="ENDING_VIEW.MESSAGE_3"
                                    />
                                </h3>
                            </Message>
                        </TextContainer>
                        {isMobileSize && <div className="spacer"></div>}
                        <Footer>
                            <img
                                src={poweredByNeurolyticsLogo}
                                alt="Neurolytics Logo"
                            />
                        </Footer>
                    </Container>
                </>
            )}
        </>
    );
}

export default withMediaQuery(withTranslation()(AssessmentEndingView));
